<template>
  <modal
      @close="$emit('close')"
      class="custom-popup medium-size disposal-info-popup"
  >
    <template slot="header">
      <div class="disposal-info-title">
        #{{DO.data.Order.getId()}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="d-flex mb-3"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['disposal_Completed','disposal_Draft','disposal_InProcess'])"></div>
          <template v-if="DO.data.Order.getStatus() === 'completed'">
            <StatusIcoBtn
                :type="'delivered'"
                class="mr-1"
            />
            {{$t('disposal_Completed.localization_value.value')}}
          </template>
          <template v-if="DO.data.Order.getStatus() === 'draft'">
            <StatusIcoBtn
                :type="'in-progress'"
                class="mr-1"
            />
            {{$t('disposal_Draft.localization_value.value')}}
          </template>
          <template v-if="DO.data.Order.getStatus() === 'in_progress'">
            <StatusIcoBtn
                :type="'in-progress'"
                class="mr-1"
            />
            {{$t('disposal_InProcess.localization_value.value')}}
          </template>
        </div>

        <div class="transaction-info__section">
          <div class="transaction-info__section-title"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['disposal_Payment'])"></div>
            {{$t('disposal_Payment.localization_value.value')}}
          </div>

          <div class="custom-row">
            <div class="custom-col custom-col--33">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['disposal_DateCompleted'])"></div>
                {{$t('disposal_DateCompleted.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    v-if="DO.data.Order.getStatus() === 'completed'"
                    :value="order['updated_at']"
                    :date="true"
                    :dateType="'DD MMM, YYYY'"
                />
                <template v-else>
                  —
                </template>
              </div>
            </div>
            <div class="custom-col custom-col--33">
              <div class="transaction-info__label"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['disposal_Cost'])"></div>
                {{$t('disposal_Cost.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="DO.data.price"
                    :costType="true"
                    :costCurrencyType="'dollar'"
                />
              </div>
            </div>
          </div>


          <div class="transaction-info__section-title mb-4"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['disposal_Products'])"></div>
            {{$t('disposal_Products.localization_value.value')}}
          </div>

          <div class="site-table-wrap table-small"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['disposal_ProductsName', 'disposal_count'])"></div>

            <div class="custom-col">
              <div class="d-flex">
                <RadioDefault
                    v-if="DO.data.Order.data.warehouse"
                    class="white-space-line width-initial mr-3"
                    :label="DO.data.Order.data.warehouse.name"
                    :name="'warehouse'"
                    :value="true"
                    :disabled="true"
                />
              </div>
            </div>

            <div class="custom-col">
              <div class="d-flex">
                <template
                    v-for="(item, index) in DISPOSAL_SUBTYPES">
                  <RadioDefault
                      v-if="item.value === DO.data.inventoryType.value"
                      class="white-space-line width-initial mr-3"
                      :key="index"
                      :label="$t(`${item.label}.localization_value.value`)"
                      :name="'subtypes'"
                      :value="DO.data.inventoryType && item.value === DO.data.inventoryType.value"
                      :disabled="true"
                  />
                </template>
              </div>
            </div>

            <div class="custom-col">
              <div class="d-flex">
                <template
                    v-for="(item, index) in DISPOSAL_PRODUCT_TYPE">
                  <RadioDefault
                      v-if="item.value === DO.data.skladType.value"
                      class="white-space-line width-initial mr-3"
                      :key="index"
                      :label="$t(`${item.label}.localization_value.value`)"
                      :name="'productType'"
                      :value="DO.data.skladType && item.value === DO.data.skladType.value"
                      :disabled="true"
                  />
                </template>
              </div>
            </div>

            <table class="site-table min-w-600">
              <thead>
              <tr>
                <th>{{$t('disposal_ProductsName.localization_value.value')}}</th>
                <th style="text-align: right">{{$t('disposal_count.localization_value.value')}}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in DO.data.productArray"
                  :key="index"
              >
                <td>
                  {{ item.getProduct().name_for_fbm }}
                </td>
                <td align="right">{{ item.getProductCount() }}</td>
              </tr>
              </tbody>
            </table>

          </div>

          <div class="transaction-info__section-title mt-3"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['disposal_Comment'])"></div>
            {{$t('disposal_Comment.localization_value.value')}}
          </div>

          <p class="disposal-info-comment">
            {{ DO.data.comment }}
          </p>

        </div>


      </div>
    </template>
  </modal>

</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import StatusIcoBtn from "../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import {DisposalOrders} from "@/components/modules/DisposalOrdersModule/models/DisposalOrders";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
  import RadioDefault from "@/components/UI/radiobuttons/RadioDefault/RadioDefault";
  import {DISPOSAL_PRODUCT_TYPE, DISPOSAL_SUBTYPES} from "@/staticData/staticVariables";

  export default {
    name: "DisposalInfo",
    components: {
      RadioDefault,
      ValueHelper,
      Modal,
      StatusIcoBtn,
    },

    props: {
      order: Object,
    },

    data(){
      return{
        DO: new DisposalOrders(),
        DISPOSAL_SUBTYPES: DISPOSAL_SUBTYPES,
        DISPOSAL_PRODUCT_TYPE: DISPOSAL_PRODUCT_TYPE,
      }
    },

    mounted() {
      this.DO.setItem(this.order)
    }

  }

</script>

<style scoped lang="scss">
  @import '../../../../../scss/colors';

  .disposal-info-popup{
    .disposal-info-comment{
      font-size: 14px;
      line-height: 16px;
      word-break: break-all;
    }

    .min-w-600{
      min-width: 600px;

      @media (max-width: 768px) {
        min-width: auto;
      }
    }

    .disposal-info-title{
      font-size: 48px;
      line-height: 56px;
    }

    .custom-popup__content{
      margin-top: -10px;
    }
  }
</style>
