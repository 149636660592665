
<template>
  <FilterBlock :showFilter="showFilter" :title="$t('common_filterBy.localization_value.value')" @close="closeFilter" @changeFilter="changeFilter">
    <template slot="body">
      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['inventory_id'])"></span>

        <DefaultInput
            :label="$t('inventory_id.localization_value.value')"
            :type="'text'"
            v-model="id"
        />
      </div>

      <div class="table-filter__item"
           v-if="isAdmin"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate(['shop_user'])"></span>
        <SearchSelect
            :options="options"
            :label="$t('shop_user.localization_value.value')"
            :optionsLabel="'email.contacts[0]'"
            :functionSearch="functionSearchUserFilter"
            @change="changeUsersFilter"
            :selected="userName"
            :typeSelect="'users'"
            :userEmail="true"
        />
      </div>

<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit"-->
<!--              @click="editTranslate(['disposal_DateCompleted'])"></span>-->
<!--        <DatePickerDefault-->
<!--            :label="$t('disposal_DateCompleted.localization_value.value')"-->
<!--            v-model="requestedDate"-->
<!--        >-->
<!--          <template slot="body">-->
<!--            <date-picker-->
<!--                v-model="requestedDate"-->
<!--                ref="datePicker"-->
<!--                valueType="format"-->
<!--                range-->
<!--                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"-->
<!--                :format="'MM/DD/YY'"-->
<!--                :placeholder="'mm/dd/yy ~ mm/dd/yy'"-->
<!--            ></date-picker>-->
<!--          </template>-->
<!--        </DatePickerDefault>-->
<!--      </div>-->

      <div class="table-filter__item"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate(['disposal_DateCompleted'])"></span>
        <DatePickerDefault
            :label="$t('disposal_DateCompleted.localization_value.value')"
            v-model="updatedDate"
        >
          <template slot="body">
            <date-picker
                v-model="updatedDate"
                ref="datePicker"
                valueType="format"
                range
                :lang="$store.getters.getDatePickerTranslations[$store.getters.GET_LANG]"
                :format="'MM/DD/YY'"
                :placeholder="'mm/dd/yy ~ mm/dd/yy'"
            ></date-picker>
          </template>
        </DatePickerDefault>
      </div>

<!--      <div class="table-filter__item"-->
<!--           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <span class="admin-edit"-->
<!--              @click="editTranslate(['inventory_status'])"></span>-->
<!--        <DefaultSelect-->
<!--            class="w-100"-->
<!--            :options="statuses"-->
<!--            :optionsLabel="'name'"-->
<!--            :label="$t('inventory_status.localization_value.value')"-->
<!--            :selected="_.find(statuses, {value: status})"-->
<!--            @change="setStatus"-->
<!--        />-->
<!--      </div>-->
    </template>
    <template slot="footer">
      <MainButton @click.native="resetFilter" :value="$t('common_resetFilters.localization_value.value')" class="secondary wfc"/>
      <MainButton @click.native="changeFilter" :value="$t('common_applyFilters.localization_value.value')" class="wfc"/>
    </template>
  </FilterBlock>
</template>

<script>
  import FilterBlock from "../../../../../../coreComponents/FilterBlock/FilterBlock";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import DefaultInput from "../../../../../../UI/inputs/DefaultInput/DefaultInput";
  import DatePickerDefault from "../../../../../../UI/inputs/DatePickerDefault/DatePickerDefault";
  import DatePicker from 'vue2-datepicker'
  import {filterFunc} from "../../../../../../../mixins/filterFuncMixin/filterFunc";
  // import DefaultSelect from "../../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import {usersFunc} from "@/mixins/usersMixins/usersFunc";
  import SearchSelect from "@/components/UI/selectiones/SearchSelect/SearchSelect";

  export default {
    name: "DisposalOrdersFilter",
    components: {
      SearchSelect,
      FilterBlock,
      MainButton,
      DatePickerDefault,
      DatePicker,
      DefaultInput,
      // DefaultSelect,
    },

    props: {
      showFilter: Boolean,
      filterGetParams: Object,
    },

    mixins: [filterFunc, usersFunc],

    data(){
      return {

        statuses: [
          {name: this.$t('inventory_Completed.localization_value.value'), value: 'completed'},
          {name: this.$t('inventory_inProcess.localization_value.value'), value: 'draft'},
        ],

        id: this.filterGetParams.id ? this.filterGetParams.id : '',
        requestedDate: this.filterGetParams.requestedDate ? this.generateFilterDate(this.filterGetParams.requestedDate, 'MM/DD/YY'): '',
        updatedDate: this.filterGetParams.updatedDate ? this.generateFilterDate(this.filterGetParams.updatedDate, 'MM/DD/YY'): '',
        status: this.filterGetParams.status ? this.filterGetParams.status : '',
        userId: this.filterGetParams.userId ? this.filterGetParams.userId : '',
        userName: this.filterGetParams.userName ? this.filterGetParams.userName : '',
        userBalance: this.filterGetParams.userBalance ? this.filterGetParams.userBalance : '',

        filterCounts: [
          'id',
          'requestedDate',
          'updatedDate',
          'status',
          'userId',
          'userName',
          'userBalance',
        ],

        options:[],
      }
    },

    watch: {
      filterGetParams(newVal) {
        this.id = newVal.id ? newVal.id : ''
        this.requestedDate = newVal.requestedDate ? this.generateFilterDate(newVal.requestedDate, 'MM/DD/YY') : ''
        this.updatedDate = newVal.updatedDate ? this.generateFilterDate(newVal.updatedDate, 'MM/DD/YY') : ''
        this.status = newVal.status ? newVal.status : ''
        this.userId = newVal.userId ? newVal.userId : ''
        this.userName = newVal.userName ? newVal.userName : ''
        this.userBalance = newVal.userBalance ? newVal.userBalance : ''
      },

    },

    methods: {
      closeFilter() {
        this.$emit('close')
      },

      resetFilter() {
        let data = this.resetFilterParams(this, this.filterCounts)

        this.$emit('changeFilter', data)
      },

      changeFilter() {
        let data = this.changeFilterParams(this, this.filterCounts)
        data.requestedDate = this.changeDateParams(this, 'requestedDate')
        data.updatedDate = this.changeDateParams(this, 'updatedDate')

        this.$emit('changeFilter', data)
      },

      setStatus(val) {
        this.status = val.value
      },
    },


  }
</script>

<style scoped>

</style>
