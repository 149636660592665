<template>
  <div class="fragment">
    <template
        v-if="$store.getters.getStorageDocuments.length > 0 && $store.getters.getStorageDocumentsLoading !== true">
      <div class="custom-row pt-4">


        <div class="custom-col custom-col--20 custom-col--xs-100 custom-col--md-50 custom-col--xl-33"
             v-for="(item, index) in $store.getters.getStorageDocuments" :key="index"
        >
          <div class="case-cart">
            <div class="case-cart__inner">
              <div class="case-cart__head">
                <div class="case-cart__columns">
                  <div class="case-cart__content-block">
                    <div class="case-cart__title disposal-orders-title" @click="toggleDisposalInfoPopup(true, item.id)">
                      #{{ item.id }}
                    </div>
                    <div class="case-cart__status-default"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['disposal_Completed','disposal_Draft','disposal_InProcess'])"></div>
                      <template v-if="item.status === 'completed'">
                        <StatusIcoBtn
                            :type="'delivered'"
                            class="mr-1"
                        />
                        {{$t('disposal_Completed.localization_value.value')}}
                      </template>
                      <template v-if="item.status === 'draft'">
                        <StatusIcoBtn
                            :type="'in-progress'"
                            class="mr-1"
                        />
                        {{$t('disposal_Draft.localization_value.value')}}
                      </template>
                      <template v-if="item.status === 'in_progress'">
                        <StatusIcoBtn
                            :type="'in-progress'"
                            class="mr-1"
                        />
                        {{$t('disposal_InProcess.localization_value.value')}}
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="case-cart__body">

                <div class="case-cart__row custom-row">
                  <div class="case-cart__col custom-col"
                       v-if="isAdmin">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['disposal_DateCompleted'])"></div>
                      {{$t('common_user.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      <TableUserColumn
                          :item="item"
                      />
                    </div>
                  </div>
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['disposal_DateCompleted'])"></div>
                      {{$t('disposal_DateCompleted.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      <ValueHelper
                          v-if="item.status === 'completed'"
                          :value="item['updated_at']"
                          :date="true"
                          :dateType="'DD MMM, YYYY'"
                      />
                      <template v-else>
                        —
                      </template>
                    </div>
                  </div>
                  <div class="case-cart__col custom-col custom-col--50">
                    <div class="case-cart__label"
                         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                      <div class="admin-edit" @click="editTranslate(['disposal_Cost'])"></div>
                      {{$t('disposal_Cost.localization_value.value')}}
                    </div>
                    <div class="case-cart__content">
                      <ValueHelper
                          :value="item"
                          :deep="'costs'"
                          :costType="true"
                          :costCurrencyType="'dollar'"
                      />
                    </div>
                  </div>
                  <div class="case-cart__col custom-col custom-col--100">
                    <div class="case-cart__content">
                      {{ item.comment }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="case-cart__bottom">
                <div class="case-cart__btn ml-auto"
                     v-if="item.status !== 'completed' || isAdmin"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit"
                      @click="editTranslate(['common_delete'])"></span>
                  <CaseCartButton
                      class="secondary-brown"
                      :value="$t('common_delete.localization_value.value')"
                      @click.native="deleteItem(item.id, index)"
                  >
                    <!--                      @click.native="updateFromEtsy(item.id)"-->
                  </CaseCartButton>
                </div>

                <div class="case-cart__btn ml-auto"
                     v-if="item.status !== 'completed' || isAdmin"
                     v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <span class="admin-edit"
                      @click="editTranslate(['common_edit'])"></span>
                  <router-link :to="$store.getters.GET_PATHS.warehouseDisposalOrders + '/' + item.id">
                    <CaseCartButton
                        :value="$t('common_edit.localization_value.value')"
                        :ico="true"
                    >
                      <template slot="ico">
                        <EditIconWhite/>
                      </template>
                    </CaseCartButton>
                  </router-link>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="table-bottom-btn">
        <div class="table-bottom-btn__inner">
          <ShowMore
              v-if="$store.getters.getStorageDocumentsCommonList.next_page_url !== null && $store.getters.getStorageDocuments.length > 0"
              @click.native="$emit('showMore')"
              v-bind:class="{'disabled-btn' : $store.getters.getNextStorageDocumentsPage}"
              :count="$store.getters.getStorageDocumentsCommonList.total - $store.getters.getStorageDocumentsForPage * $store.getters.getStorageDocumentsCommonList.current_page < $store.getters.getStorageDocumentsForPage ?
                    $store.getters.getStorageDocumentsCommonList.total - $store.getters.getStorageDocumentsForPage * $store.getters.getStorageDocumentsCommonList.current_page:
                    $store.getters.getStorageDocumentsForPage"
          />
        </div>
      </div>
    </template>

    <div class="fragment pt-4"
         v-if="$store.getters.getStorageDocumentsLoading === false && $store.getters.getStorageDocuments.length === 0">
      <NoResult
          :countFilterParams="countFilterParams"
          :title="$t('common_noDataYet.localization_value.value')"
          :titleWithFilter="$t('common_noResult.localization_value.value')"
          :text="$t('common_noDataYetTxt.localization_value.value')"
          :textWithFilter="$t('common_noResultSeems.localization_value.value')"
          @resetFilter="$emit('resetFilter')"
      />
    </div>

    <DisposalInfo
        v-if="disposalInfoPopup"
        :order="order"
        @close="toggleDisposalInfoPopup(false)"/>

  </div>
</template>

<script>


import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
import DisposalInfo from "../../../../popups/DisposalInfoPopup/DisposalInfo";
import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";
import ShowMore from "@/components/coreComponents/Buttons/ShowMore/ShowMore";
import NoResult from "@/components/coreComponents/NoResult/NoResult";
import CaseCartButton from "@/components/UI/buttons/CaseCartButton/CaseCartButton";
import EditIconWhite from "../../../../../../../../public/img/UI-group/edit-icon-white_.svg?inline"
import TableUserColumn from "@/components/coreComponents/TableComponents/TableUserColumn/TableUserColumn";

export default {
  name: "DisposalOrdersTable",
  components: {
    TableUserColumn,
    CaseCartButton,
    NoResult,
    ShowMore,
    ValueHelper,
    DisposalInfo,
    StatusIcoBtn,
    EditIconWhite,
  },

  props: {
    countFilterParams: Number,
  },

  data() {
    return {
      disposalInfoPopup: false,
      order: false,
    }
  },

  methods: {
    toggleDisposalInfoPopup(val, id) {
      if (!val) {
        this.order = false
        this.disposalInfoPopup = val
        return
      }

      this.$store.dispatch('getStorageDocumentsDisposal', id).then(response => {
        this.order = this.getRespData(response)
        this.disposalInfoPopup = val
      })
    },

    deleteItem(id, index) {
      let text = {
        title: 'common_deleteConfirm',
        txt: 'common_deleteItemAsk',
        yes: 'common_confirmDelete',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.$store.dispatch('deleteStorageDocuments', id).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            let storageDocuments = this.$store.getters.getStorageDocuments
            storageDocuments.splice(index, 1)

            this.openNotify('success', 'common_notificationRecordDeleted')
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

  }
}
</script>

<style scoped lang="scss">
@import "../../../../../../../scss/colors.scss";

.case-cart__content{
  word-break: break-all;
}

.unidentified-payments-table {
  padding-top: 24px;
}

.disposal-orders-title {
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
  color: $accent;
  cursor: pointer;
}
</style>
